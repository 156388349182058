import { ApplicationRef, ChangeDetectionStrategy, Component, effect, ElementRef, Injector, Input, OnDestroy, OnInit, signal, ViewChild, ViewContainerRef } from "@angular/core";
import { merge, Subject, timer } from "rxjs";
import { ACTIVE_PILOTAGE_STATES, compareState, createTextMatcher, Duration, Instant, mapNotNull, MMSI } from "common";
import { bufferTime, debounceTime, map, takeUntil, tap } from "rxjs/operators";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CameraOptions, GeoJSONSource, LngLat, Map as MapBoxMap, NavigationControl } from "mapbox-gl";
import { VesselPopupComponent, VesselPopupDelegate } from "../vessel-popup/vessel-popup.component";
import { defaultMapOptions, MapOptions, MapOptionsComponent, MapOptionsDelegate } from "../map-options/map-options.component";
import { VesselData } from "../vessel-data";
import { DistanceMeasurer } from "../distance-measurer/distance-measurer";
import { MapboxAngularPopup } from "../mapbox/mapbox-angular-popup";
import { MapDataProvider } from "../map-data-provider.service";
import { CameraFeature, createCameraFeatures, createPointOfInterestFeatures, createStationFences, createVesselFeatures, createVesselLeaders, VesselFeature } from "../geojson-mappings";
import { observeMapBoxEvent } from "../mapbox/mapbox-rx";
import { PilotageInfo, VesselLocationEvent, VesselMetadataEvent } from "../types";
import { CameraPopupComponent } from "../camera-popup/camera-popup.component";

const LOCATION_EVENT_VALIDITY = Duration.ofMinutes(15);
const DEFAULT_LOCATION = new LngLat(24.5, 62.0);
const DEFAULT_ZOOM_LEVEL = 5;
const MIN_ZOOM_LEVEL = 4;
const MAX_ZOOM_LEVEL = 14;
const MIN_ZOOM_LEVEL_FOR_NAMES = 9;
const MIN_ZOOM_LEVEL_FOR_CAMERA_NAMES = 11;

const LAYER_VESSELS = "vessels";
const LAYER_VESSEL_LEADERS = "vessel-leaders";
const LAYER_DISTANCE_MEASURER = "distance-measurer";
const LAYER_CHART_SYMBOLS = "chart-symbols";
const LAYER_PILOT_BOARDING_AREAS = "pilot-boarding-areas";
const LAYER_CAMERAS = "cameras";
const LAYER_STATION_FENCES = "station-fences";

const SOURCE_OPENSEAMAP = "openseamap-source";
const SOURCE_POINTS_OF_INTEREST = "points-of-interest-source";
const SOURCE_CAMERAS = "cameras-source";
const SOURCE_VESSELS = "vessels-source";
const SOURCE_VESSEL_LEADERS = "vessel-leaders-source";
const SOURCE_DISTANCE_MEASURER = "distance-measurer-source";
const SOURCE_STATION_FENCES = "station-fences-source";

@Component({
    selector: "app-map",
    template: `
        <div class="!absolute top-0 bottom-0 left-0 right-0" #mapElement></div>
    `,
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    host: {
        '(window:keydown.Alt)': 'altPressed.set(true)',
        '(window:keyup.Alt)': 'altPressed.set(false)',
        '(keydown.Escape)': 'onEscape()',
    },
})
export class MapComponent implements OnInit, OnDestroy, VesselPopupDelegate, MapOptionsDelegate {

    @ViewChild("mapElement", {static: true}) mapElement!: ElementRef;

    private readonly vesselsByMMSI = new Map<MMSI, VesselData>();
    private distanceMeasurers: DistanceMeasurer[] = [];
    private options = defaultMapOptions;
    private mapBox!: MapBoxMap;
    private openedVesselPopup: MapboxAngularPopup<VesselPopupComponent> | null = null;
    private openedCameraPopup: MapboxAngularPopup<CameraPopupComponent> | null = null;
    private readonly disposed$ = new Subject<void>();
    private readonly measuringDistance = signal(false);
    readonly altPressed = signal(false);
    private readonly overVessel = signal(false);
    private readonly overCamera = signal(false);

    @Input()
    internal = false;

    constructor(
        private readonly mapDataProvider: MapDataProvider,
        private readonly applicationRef: ApplicationRef,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly injector: Injector,
    ) {
    }

    ngOnInit(): void {
        this.mapBox = new MapBoxMap({
            container: this.mapElement.nativeElement,
            style: "mapbox://styles/finnpilot-pilotweb/ckf4aexrz0wpo19qo1862l6v4",
            center: DEFAULT_LOCATION,
            zoom: DEFAULT_ZOOM_LEVEL,
            ...cameraOptionsFromUrl(this.activatedRoute.params),
            minZoom: MIN_ZOOM_LEVEL,
            maxZoom: MAX_ZOOM_LEVEL,
            dragRotate: false,
            pitchWithRotate: false,
            touchPitch: false,
            maxBounds: [
                {lat: 52, lng: 0},  // sw
                {lat: 72, lng: 50}, // ne
            ],
            accessToken: "pk.eyJ1IjoiZmlubnBpbG90LXBpbG90d2ViIiwiYSI6ImNrZjN6ajc5bTA4NmIydm9mMzNkMnZuM2sifQ.W0owJnRiMP4cTF8En-IHCQ"
        });

        this.mapBox.addControl(new NavigationControl({showCompass: false}), "top-left");

        // Allow touch zooming, but disallow rotation
        this.mapBox.touchZoomRotate.disableRotation();

        this.mapBox.on("load", () => this.initMap(this.mapBox));

        effect(() => {
            if (this.altPressed() || this.measuringDistance())
                this.mapBox.getCanvas().style.cursor = "crosshair";
            else if (this.overCamera() || this.overVessel())
                this.mapBox.getCanvas().style.cursor = "pointer";
            else
                this.mapBox.getCanvas().style.cursor = "";
        }, {injector: this.injector});
    }

    initMap(mapBox: MapBoxMap): void {
        mapBox.addSource(SOURCE_OPENSEAMAP, {
            type: "raster",
            tiles: ["https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"],
            minzoom: 6,
            maxzoom: 18,
            attribution: "Map data: &copy; <a href='https://www.openseamap.org'>OpenSeaMap</a> contributors"
        });

        mapBox.addSource(SOURCE_VESSELS, {type: "geojson", data: {type: "FeatureCollection", features: []}});
        mapBox.addSource(SOURCE_VESSEL_LEADERS, {type: "geojson", data: {type: "FeatureCollection", features: []}});
        mapBox.addSource(SOURCE_DISTANCE_MEASURER, {type: "geojson", data: {type: "FeatureCollection", features: []}});

        mapBox.addLayer({id: LAYER_CHART_SYMBOLS, type: "raster", source: SOURCE_OPENSEAMAP});
        mapBox.addLayer({
            id: LAYER_VESSELS,
            type: "symbol",
            source: SOURCE_VESSELS,
            layout: {
                "icon-image": "{iconImage}",
                "text-field": "{title}",
                "text-font": [
                    "Open Sans Semibold",
                    "Arial Unicode MS Bold"
                ],
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "icon-ignore-placement": true,
                "text-justify": "left",
                "text-offset": [1, 1.25],
                "text-anchor": "left",
                "icon-rotate": ["get", "heading"],
                "text-size": {
                    "stops": [
                        [0, 0],
                        [MIN_ZOOM_LEVEL_FOR_NAMES - 0.1, 0],
                        [MIN_ZOOM_LEVEL_FOR_NAMES, 10]
                    ],
                }
            }
        });

        mapBox.addLayer({
            id: LAYER_VESSEL_LEADERS,
            type: "line",
            source: SOURCE_VESSEL_LEADERS,
            minzoom: 6,
            paint: {
                "line-color": "#777"
            }
        });

        mapBox.addLayer({
            id: LAYER_DISTANCE_MEASURER,
            type: "line",
            source: SOURCE_DISTANCE_MEASURER,
            layout: {
                "line-cap": "round",
                "line-join": "round",
            },
            paint: {
                "line-dasharray": [2, 2],
                "line-color": "#3388ff",
                "line-width": ["get", "width"]
            }
        });

        if (this.internal) {
            this.mapDataProvider.findPointsOfInterest().then(points => {
                if (points.length === 0) return;

                mapBox.addSource(SOURCE_POINTS_OF_INTEREST, {type: "geojson", data: createPointOfInterestFeatures(points)});

                mapBox.addLayer({
                    id: "pilotweb-points-of-interest",
                    type: "symbol",
                    source: SOURCE_POINTS_OF_INTEREST,
                    paint: {
                        "text-color": "#10520a"
                    },
                    layout: {
                        "text-field": "{name}",
                        "text-size": 10,
                        "icon-image": "{icon}",
                        "text-anchor": "bottom",

                        "text-offset": [
                            0,
                            -0.8
                        ]
                    },
                }, LAYER_VESSELS);
            });

            this.mapDataProvider.findStationFences().then(fences => {
                mapBox.addSource(SOURCE_STATION_FENCES, {type: "geojson", data: createStationFences(fences)});

                mapBox.addLayer({
                    id: LAYER_STATION_FENCES,
                    type: "line",
                    source: SOURCE_STATION_FENCES,
                    minzoom: 12,
                    paint: {
                        "line-dasharray": [2, 2],
                        "line-color": "#000",
                        "line-width": 1
                    }
                });
            });

            mapBox.addSource(SOURCE_CAMERAS, {type: "geojson", data: {type: "FeatureCollection", features: []}});

            mapBox.addLayer({
                id: LAYER_CAMERAS,
                type: "symbol",
                source: SOURCE_CAMERAS,
                paint: {
                    "text-color": "#ff0000"
                },
                layout: {
                    "text-field": "{name}",
                    "text-size": {
                        "stops": [
                            [0, 0],
                            [MIN_ZOOM_LEVEL_FOR_CAMERA_NAMES - 0.1, 0],
                            [MIN_ZOOM_LEVEL_FOR_CAMERA_NAMES, 10]
                        ],
                    },
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true,
                    "text-ignore-placement": true,
                    "icon-ignore-placement": true,
                    "icon-image": "camera",
                    "text-anchor": "bottom",
                    "text-offset": [
                        0,
                        -0.8
                    ],
                },
            }, "pilot-boarding-areas");


            mapBox.on("click", LAYER_CAMERAS, e => {
                e.preventDefault();
                e.originalEvent.stopPropagation();

                if (this.measuringDistance())
                    return;

                const features = (e?.features ?? []) as unknown[] as CameraFeature[];
                this.openCameraPopup(features);
            });

            mapBox.on("mouseenter", LAYER_CAMERAS, () => this.overCamera.set(true));
            mapBox.on("mouseleave", LAYER_CAMERAS, () => this.overCamera.set(false));

            this.mapDataProvider.cameras$.pipe(takeUntil(this.disposed$)).subscribe(cameras => {
                const source = mapBox.getSource(SOURCE_CAMERAS) as GeoJSONSource;
                source.setData(createCameraFeatures(cameras));
            });
        }

        mapBox.on("click", e => {
            if (this.measuringDistance())
                return;

            if (e.originalEvent.altKey) {
                this.startDistanceMeasurement(e.lngLat);
            } else {
                const vessels = mapBox.queryRenderedFeatures(e.point, {layers: [LAYER_VESSELS]}) as unknown as VesselFeature[];
                if (vessels.length) {
                    // If there are multiple vessels on top of each other, prefer pilotages
                    const feature = vessels.find(it => it.properties.hasPilotage) ?? vessels[0];
                    const vessel = this.vesselsByMMSI.get(feature.properties.mmsi!);
                    if (vessel) {
                        const coords = feature.geometry.coordinates;
                        this.openVesselPopup(vessel, new LngLat(coords[0], coords[1]));
                    }
                }
            }
        });

        mapBox.on("mouseenter", LAYER_VESSELS, () => this.overVessel.set(true));
        mapBox.on("mouseleave", LAYER_VESSELS, () => this.overVessel.set(false));

        this.updateMapData();

        const optionsControl = MapOptionsComponent.create(this.applicationRef, this.viewContainerRef, this.injector, this);

        mapBox.addControl(optionsControl, "top-right");

        const updateMapFromParams = (ps: Params) => void mapBox.jumpTo(cameraOptionsFromUrl(ps));

        updateMapFromParams(this.activatedRoute.snapshot);

        this.mapDataProvider.pilotages$.pipe(takeUntil(this.disposed$)).subscribe(ps => {
            const pilotages = pickBestPilotageForEachVessel(ps.filter(it => ACTIVE_PILOTAGE_STATES.includes(it.state)));

            const pilotageMmsis = new Set<number>();
            for (const pilotage of pilotages)
                if (pilotage.vesselMmsi != null) {
                    const mmsi = pilotage.vesselMmsi;
                    pilotageMmsis.add(mmsi);
                    const vessel = this.getVessel(mmsi);
                    vessel.pilotage = pilotage;
                }

            for (const vessel of this.vesselsByMMSI.values())
                if (!pilotageMmsis.has(vessel.mmsi))
                    vessel.pilotage = null;

            this.updateMapData();
        });

        // Check expirations periodically
        timer(60_000, 60_000).pipe(takeUntil(this.disposed$)).subscribe(() => {
            const cutoff = Instant.now().minus(LOCATION_EVENT_VALIDITY);
            for (const vessel of Array.from(this.vesselsByMMSI.values()))
                if (vessel.isExpired(cutoff)) {
                    this.vesselsByMMSI.delete(vessel.mmsi);
                }

            this.updateMapData();
        });

        // Map coordinates and zoom level in and out
        this.activatedRoute.queryParams.subscribe(updateMapFromParams);

        merge(
            observeMapBoxEvent(mapBox, "zoomend"),
            observeMapBoxEvent(mapBox, "moveend")
        ).pipe(debounceTime(100), takeUntil(this.disposed$)).subscribe(() => {
            const center = mapBox.getCenter();
            this.router.navigate([], {
                queryParams: {
                    // Use accuracy of about 100m for coordinates
                    lat: center.lat.toFixed(3),
                    lng: center.lng.toFixed(3),
                    zoom: mapBox.getZoom().toFixed(2)
                },
                replaceUrl: true,
                queryParamsHandling: "merge"
            });
        });

        const processLocationEvents = (batch: ReadonlyArray<VesselLocationEvent>): void => {
            const validityCutoff = Instant.now().minus(LOCATION_EVENT_VALIDITY);
            const openPopup = this.openedVesselPopup;
            for (const event of batch) {
                if (event.timestamp.isBefore(validityCutoff) || (event.sog ?? 0) > 40)
                    continue; // skip crap

                this.getVessel(event.mmsi).updateLocation(event);

                // If a popup is open for this event, update the location of the popup
                if (openPopup != null && openPopup.componentInstance.mmsi === event.mmsi)
                    openPopup.setLocation(new LngLat(event.point.lng, event.point.lat));
            }
        };

        const processMetadataEvents = (batch: ReadonlyArray<VesselMetadataEvent>): void => {
            for (const vessel of batch)
                this.getVessel(vessel.mmsi).updateMetadata(vessel);
        };

        // TODO: perform full refetches if we detect that the page has been paused

        this.mapDataProvider.getCurrentAisData().then(data => {
            processLocationEvents(mapNotNull(data.vessels, it => it.location));
            processMetadataEvents(mapNotNull(data.vessels, it => it.metadata));

            this.updateMapData();

            const mmsi = +this.activatedRoute.snapshot.queryParams["mmsi"];
            if (mmsi)
                this.selectVesselByMmsi(mmsi as MMSI);

            const locations$ = this.mapDataProvider.subscribeToLocationUpdates(data).pipe(tap(processLocationEvents));
            const metadata$ = this.mapDataProvider.subscribeToMetadataUpdates(data).pipe(tap(processMetadataEvents));

            merge(locations$, metadata$).pipe(map(() => null), bufferTime(10_000), takeUntil(this.disposed$)).subscribe({
                next: () => this.updateMapData(),
                error: e => console.error("Failed to receive location/metadata update", e),
            });
        });
    }

    /**
     * Convert in-memory data to GeoJSON and send it to map component.
     */
    private updateMapData(): void {
        const vesselsSource = this.mapBox.getSource(SOURCE_VESSELS) as GeoJSONSource;
        const vesselLeadersSource = this.mapBox.getSource(SOURCE_VESSEL_LEADERS) as GeoJSONSource;

        const vessels = this.displayableVessels;
        vesselsSource.setData(createVesselFeatures(vessels));
        vesselLeadersSource.setData(createVesselLeaders(vessels, this.options.projectedCourseMinutes));
        this.distanceMeasurers.forEach(it => it.recalculateStart());

        this.mapBox.setLayoutProperty(LAYER_DISTANCE_MEASURER, "visibility", this.distanceMeasurers.length !== 0 ? "visible" : "none");
        this.mapBox.setLayoutProperty(LAYER_CHART_SYMBOLS, "visibility", this.options.chartSymbols ? "visible" : "none");
    }

    private closeExistingPopups(): void {
        this.openedVesselPopup?.remove();
        this.openedVesselPopup = null;

        this.openedCameraPopup?.remove();
        this.openedCameraPopup = null;
    }

    private openVesselPopup(vessel: VesselData, lngLat: LngLat): void {
        this.closeExistingPopups();

        this.openedVesselPopup = VesselPopupComponent.create(this.mapBox, this.applicationRef, this.viewContainerRef, this.injector, vessel, this);
        this.openedVesselPopup.onClose(() => this.openedVesselPopup = null);
        this.openedVesselPopup.setLocation(lngLat);
    }

    private openCameraPopup(cameras: CameraFeature[]): void {
        if (cameras.length === 0)
            return;

        const lngLat = LngLat.convert(cameras[0].geometry.coordinates as [number, number]);
        this.closeExistingPopups();

        this.openedCameraPopup = CameraPopupComponent.create(this.mapBox, this.applicationRef, this.viewContainerRef, this.injector, cameras);
        this.openedCameraPopup.onClose(() => this.openedCameraPopup = null);
        this.openedCameraPopup.setLocation(lngLat);
    }

    onEscape(): void {
        this.closeExistingPopups();
        this.removeAllDistanceMeasurements();
    }

    startDistanceMeasurement(data: VesselData | LngLat): void {
        this.openedVesselPopup?.remove();
        this.distanceMeasurers.forEach(it => it.stopMeasuring());

        let vessel: VesselData | undefined = undefined;
        let startPoint: () => LngLat;
        if (isLngLat(data)) {
            startPoint = () => data;
        } else {
            vessel = data;
            startPoint = () => data.location;
        }

        this.distanceMeasurers.push(new DistanceMeasurer(
            this,
            this.mapBox,
            vessel,
            startPoint,
            [LAYER_PILOT_BOARDING_AREAS],
            this.applicationRef,
            this.viewContainerRef,
            this.injector
        ));
        this.updateDistanceLayer();
        this.measuringDistance.set(true);
        this.updateMapData();
    }

    removeAllDistanceMeasurements(): void {
        if (this.distanceMeasurers.length !== 0) {
            for (const measurer of this.distanceMeasurers)
                measurer.dispose();

            this.distanceMeasurers = [];
            this.measuringDistance.set(false);
            this.updateMapData();
        }
    }

    updateDistanceLayer(): void {
        const source = this.mapBox.getSource(SOURCE_DISTANCE_MEASURER) as GeoJSONSource;

        source.setData({
            type: "FeatureCollection",
            features: this.distanceMeasurers.map(it => it.createRenderFeature())
        });
    }

    distanceMeasuringStopped(_: DistanceMeasurer): void {
        this.recalculateMeasuringDistance();
    }

    removeDistanceMeasurer(measurer: DistanceMeasurer): void {
        this.distanceMeasurers = this.distanceMeasurers.filter(it => it !== measurer);
        measurer.dispose();
        this.updateDistanceLayer();
        this.updateMapData();

        this.recalculateMeasuringDistance();
    }

    private recalculateMeasuringDistance(): void {
        this.measuringDistance.set(this.distanceMeasurers.some(it => !it.isStopped()));
    }

    findVessels(query: string, _pilotagesOnly: boolean): VesselData[] {
        if (query === "") return [];

        const matcher = createTextMatcher(query);
        return this.displayableVessels.filter(v => v.hasLocation && matcher([v.title, v.mmsi.toString()]));
    }

    private get displayableVessels(): VesselData[] {
        const allVessels = Array.from(this.vesselsByMMSI.values());
        const additionalVessels = new Set(mapNotNull(this.distanceMeasurers, it => it.vesselMmsi));
        return this.options.pilotagesOnly ? allVessels.filter(it => it.isPilotageRelated || additionalVessels.has(it.mmsi)) : allVessels;
    }

    private selectVesselByMmsi(mmsi: MMSI): void {
        const vessel = this.vesselsByMMSI.get(mmsi);
        if (vessel)
            this.vesselSelected(vessel);
    }

    vesselSelected(vessel: VesselData): void {
        const location = new LngLat(vessel.location.lng, vessel.location.lat);
        if (this.mapBox.getCenter().distanceTo(location) <= 0.01) {
            this.openVesselPopup(vessel, vessel.location);
        } else {
            this.mapBox.flyTo({center: location, animate: true});
            this.mapBox.once("moveend", () => this.openVesselPopup(vessel, location));
        }
    }

    optionsChanged(options: MapOptions): void {
        this.options = options;

        this.updateMapData();
    }

    private getVessel(mmsi: MMSI): VesselData {
        let vessel = this.vesselsByMMSI.get(mmsi);
        if (vessel == null) {
            vessel = new VesselData(mmsi);
            this.vesselsByMMSI.set(mmsi, vessel);
        }
        return vessel;
    }

    ngOnDestroy(): void {
        this.disposed$.next();
        this.disposed$.complete();
    }
}

function cameraOptionsFromUrl(params: Params): CameraOptions {
    const zoom = parseFloat(params["zoom"]);
    const lat = parseFloat(params["lat"]);
    const lng = parseFloat(params["lng"]);

    const result: CameraOptions = {};
    if (!Number.isNaN(lat) && !Number.isNaN(lng))
        result.center = new LngLat(lng, lat);
    if (zoom)
        result.zoom = zoom;

    return result;
}

function pickBestPilotageForEachVessel(pilotages: ReadonlyArray<PilotageInfo>): PilotageInfo[] {
    const pilotagesByMMSI = new Map<number, PilotageInfo>();

    for (const pilotage of pilotages) {
        if (pilotage.vesselMmsi != null) {
            const mmsi = pilotage.vesselMmsi;
            const previous = pilotagesByMMSI.get(mmsi);
            if (previous == null || compareState(pilotage.state, previous.state) > 0)
                pilotagesByMMSI.set(mmsi, pilotage);
        }
    }

    return Array.from(pilotagesByMMSI.values());
}

function isLngLat(data: unknown): data is LngLat {
    return (data as LngLat).lat !== undefined;
}
