import { ApplicationRef, ChangeDetectionStrategy, Component, Inject, InjectionToken, Injector, Optional, signal, ViewContainerRef } from "@angular/core";
import { LngLat, Map } from "mapbox-gl";
import { VesselData } from "../vessel-data";
import { calculateEtaInMinutes, metersToNauticalMiles } from "../conversions";
import { getDistance } from "geolib";
import { DistanceMeasurer, formatEta } from "./distance-measurer";
import { MapboxAngularPopup } from "../mapbox/mapbox-angular-popup";
import { DecimalPipe } from "@angular/common";

const DISTANCE_POPUP_DATA_KEY = new InjectionToken("DISTANCE_POPUP_DATA_KEY");
const DISTANCE_POPUP_DELEGATE_KEY = new InjectionToken("DISTANCE_POPUP_DELEGATE_KEY");

@Component({
    templateUrl: './distance-popup.component.html',
    styles: `
        :host {
            display: block;
            width: 220px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DecimalPipe
    ]
})
export class DistancePopupComponent {

    readonly viewData = signal<DistancePopupViewData>({
        vesselName: '',
        distance: 0,
        speed: null,
        eta: null
    });

    constructor(
        @Optional() @Inject(DISTANCE_POPUP_DATA_KEY) private readonly vessel: VesselData | undefined,
        @Inject(DISTANCE_POPUP_DELEGATE_KEY) private readonly measurer: DistanceMeasurer
    ) {
    }

    get isMeasuring(): boolean {
        return !this.measurer.isStopped();
    }

    updateData(points: LngLat[]): void {
        const distance = metersToNauticalMiles(pathLength(points));
        const eta = this.vessel ? calculateEtaInMinutes(distance, this.vessel.speed) : null;

        this.viewData.set({
            vesselName: this.vessel?.title,
            distance: Math.round(distance),
            eta: (eta != null) ? formatEta(eta) : null,
            speed: this.vessel?.speed
        });
    }

    follow(e: Event): void {
        e.stopPropagation();
        e.preventDefault();

        this.measurer.follow();
    }

    static create(mapBox: Map,
                  applicationRef: ApplicationRef,
                  viewContainerRef: ViewContainerRef,
                  parentInjector: Injector,
                  vessel: VesselData | undefined,
                  measurer: DistanceMeasurer): MapboxAngularPopup<DistancePopupComponent> {
        const injector = Injector.create({
            providers: [
                {provide: DISTANCE_POPUP_DATA_KEY, useValue: vessel},
                {provide: DISTANCE_POPUP_DELEGATE_KEY, useValue: measurer},
            ], parent: parentInjector
        });

        return new MapboxAngularPopup(mapBox, applicationRef, viewContainerRef.createComponent(DistancePopupComponent, {injector}), {closeOnClick: false}, false, false);
    }
}

interface DistancePopupViewData {
    vesselName: string | undefined;
    distance: number;
    speed: number | null | undefined;
    eta: string | null;
}

/**
 * Returns the length of path in meters.
 */
function pathLength(path: ReadonlyArray<LngLat>): number {
    let total = 0;

    for (let i = 1; i < path.length; i++)
        total += getDistance(path[i - 1], path[i]);

    return total;
}

